import { create } from 'zustand';
import type { CandidateStore } from './candidate.store.types';
import { default as Candidate } from 'model/Candidate';

export const useCandidateStore = create<CandidateStore>((set) => ({
  selectedCandidate: null,
  setSelectedCandidate: (campaignId, rawCandidate) =>
    set((state) => {
      if (state.selectedCandidate && state.selectedCandidate.es_person_id === rawCandidate.es_person_id) {
        rawCandidate.reasons = state.selectedCandidate.reasons;
      }
      return { selectedCandidate: new Candidate(campaignId, rawCandidate) };
    }),
  setCandidateLabels(labels) {
    set((state) => {
      if (!state.selectedCandidate) return {};

      const candidate = state.selectedCandidate;
      const campaignId = candidate.campaignId;

      return {
        selectedCandidate: new Candidate(campaignId, {
          ...candidate.raw,
          reasons: labels,
        }),
      };
    });
  },
}));
