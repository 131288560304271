import { routes } from 'config';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { PageContext } from './usePageContext.types';

export const usePageContext = (): PageContext => {
  const isSharePage = useMatch(`${routes.sharedCandidatePage()}`);
  const isCandidatePage = useMatch(`${routes.candidatePage()}`);
  const isCentralInboxPage = useMatch(`${routes.centralInbox}`);
  const isHomePage = useMatch(`${routes.home}`);
  const isRatedCandidatesPage = useMatch(`${routes.ratedCandidates}`);
  const isSearchv3 = useMatch(`searchv3`);

  const pageContext = useMemo(() => {
    if (isSharePage) return PageContext.Shared;
    if (isCandidatePage) return PageContext.Candidate;
    if (isHomePage) return PageContext.Home;
    if (isRatedCandidatesPage) return PageContext.RatedCandidates;
    if (isCentralInboxPage) return PageContext.CentralInbox;

    /// TODO: verify how this logic will work for searchv3
    // eslint-disable-next-line no-constant-condition
    if (isSearchv3 || true) return PageContext.Campaign;
  }, [isSharePage, isCandidatePage, isSearchv3, isHomePage, isCentralInboxPage]);

  return pageContext;
};
