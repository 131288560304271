import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import useConversationsQuery from 'shared/hooks/useConversationsQuery';
import { InboxConversationList } from '../InboxConversationList/InboxConversationList';

export const InboxConversationPreview = () => {
  const {
    data: { results: conversations },
    isLoading,
  } = useConversationsQuery({});

  const pageContext = usePageContext();

  return (
    pageContext !== PageContext.CentralInbox && (
      <InboxConversationList
        showTeamView={false}
        showFilters={false}
        conversations={conversations}
        isLoading={isLoading}
      />
    )
  );
};
