import type { InboxConversationListBodyProps } from '../../CentralInbox.types';
import { useStyles } from './InboxConversationListBody.styles';
import { useCandidateStore } from 'stores/candidate/candidate.store';
import React, { useRef, useCallback } from 'react';
import { useInfiniteConversationsQuery } from 'shared/hooks/useInfiniteConversationsQuery';
import { InboxConversationListSkeleton } from '../InboxConversationListSkeleton';
import { usePageContext } from 'hooks/shared/usePageContext';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useNavigate } from 'react-router-dom';
import { routes } from 'config/routes';
import { throttle } from 'lodash';
import { InboxConversationItem } from '../InboxConversationItem/InboxConversationItem';

export const InboxConversationListBody = ({ conversations }: InboxConversationListBodyProps) => {
  const classes = useStyles();
  const setSelectedCandidate = useCandidateStore((state) => state.setSelectedCandidate);
  const { hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteConversationsQuery();
  const pageContext = usePageContext();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(
    throttle((e: React.UIEvent<HTMLDivElement>) => {
      const target = e.currentTarget;
      if (!target) return;

      const { scrollHeight, scrollTop, clientHeight } = target;
      const distanceFromBottom = scrollHeight - clientHeight - scrollTop;

      if (hasNextPage && distanceFromBottom < 40 && !isFetchingNextPage) {
        fetchNextPage();
      }
    }, 30),
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  return (
    <div css={classes.root} ref={containerRef} onScroll={handleScroll}>
      {conversations.map((conversation) => (
        <div
          key={conversation.id}
          onClick={() => {
            if (conversation.candidate) {
              setSelectedCandidate(conversation.candidate?.campaign.id, conversation.candidate);
            }

            if (pageContext !== PageContext.CentralInbox) {
              navigate(routes.centralInbox);
            }
          }}>
          <InboxConversationItem conversation={conversation} />
        </div>
      ))}

      {isFetchingNextPage && <InboxConversationListSkeleton />}
    </div>
  );
};
