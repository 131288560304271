import { useQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { conversationsClient } from 'clients/ConversationsClient/ConversationsClient';
import { GetConversationsParams } from 'clients/ConversationsClient/ConversationsClient.types';
import { InboxFilters } from 'components/ui/organisms/CentralInbox/CentralInbox.types';
import { QueryKey } from 'types/query';

const useConversationsQuery = ({
  limit = 5,
  offset = 0,
  conversationScope = InboxFilters.ALL,
  teamView = true,
}: GetConversationsParams) => {
  return useQuery(
    [QueryKey.getConversations, { limit, offset, conversationScope, teamView }],
    async () => {
      const conversationsResponse = await conversationsClient.getConversations({
        limit,
        offset,
        conversationScope,
        teamView,
      });

      const esPersonIds = conversationsResponse.data.results.map((conversation) => conversation.es_person_id);

      const candidatesResponse = await campaignsClient.searchEsCampaignCandidate({ esPersonIds });

      return {
        ...conversationsResponse.data,
        results: conversationsResponse.data.results.map((result: any) => ({
          ...result,
          user: {
            firstName: result.user.first_name,
            lastName: result.user.last_name,
          },
          unreadCount: result.unread_count,
          lastMessageDate: result.last_message_date,
          lastMessageFromRecruiter: result.last_message_from_recruiter,
          candidateName: result.candidate_name,
          esPersonId: result.es_person_id,
          conversationSource: result.conversation_source,
          candidate: candidatesResponse.data.results.find((candidate) => candidate.es_person_id == result.es_person_id),
        })),
      };
    },
    {
      initialData: {
        count: 0,
        next: null,
        previous: null,
        results: [],
      },
      initialDataUpdatedAt: 0,
    },
  );
};

export default useConversationsQuery;
