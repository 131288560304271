import type { InboxConversationListHeaderProps } from '../../CentralInbox.types';

import { Loader } from '@recruitrobin/robin-theme/web-components';
import { Switch } from 'components/ui/atoms';
import { useContext, useState } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useInfiniteConversationsQuery } from 'shared/hooks/useInfiniteConversationsQuery';
import { useStyles } from './InboxConversationListHeader.styles';

export const InboxConversationListHeader = ({
  showTeamView = true,
  isLoading = true,
}: InboxConversationListHeaderProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);
  const [teamView, setTeamView] = useState(true);
  const { data } = useInfiniteConversationsQuery();
  const countConversations = data?.pages[0].count;

  return (
    <div css={classes.root}>
      <div css={classes.labels}>
        <span css={classes.label}>{dictionary.inbox}</span>
        <span css={classes.unreadConversationsAmount}>
          {isLoading ? <Loader color={(c) => c.neutral.gray} size={16} /> : countConversations}
        </span>
      </div>
      {showTeamView && (
        <Switch
          onChange={() => setTeamView(!teamView)}
          checked={teamView}
          label={{ off: dictionary.teamView.toUpperCase() }}
          labelVariant={(v) => v.supporting['label-tiny']}
          variant="smaller"
        />
      )}
    </div>
  );
};
