import type { InboxConversationListProps } from '../CentralInbox.types';

import { Typography } from '@recruitrobin/robin-theme/web-components';
import { useContext } from 'react';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { useStyles } from './InboxConversationList.styles';
import { InboxConversationListBody } from './InboxConversationListBody/InboxConversationListBody';
import { InboxConversationListFilters } from './InboxConversationListFilters/InboxConversationListFilters';
import { InboxConversationListHeader } from './InboxConversationListHeader/InboxConversationListHeader';
import { InboxConversationListSkeleton } from './InboxConversationListSkeleton';

export const InboxConversationList = ({
  showTeamView = true,
  showFilters = true,
  conversations = [],
  isLoading = true,
}: InboxConversationListProps) => {
  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div css={classes.root}>
      <InboxConversationListHeader showTeamView={showTeamView} isLoading={isLoading} />
      <div css={classes.divider} />
      {showFilters && <InboxConversationListFilters />}
      {isLoading ? (
        <InboxConversationListSkeleton />
      ) : conversations.length > 0 ? (
        <InboxConversationListBody conversations={conversations} />
      ) : (
        <div css={classes.flexAlignment}>
          <Typography variant={(v) => v.heading[5]} color={(v) => v.neutral.gray[50]}>
            {dictionary.emptyConversations}
          </Typography>
        </div>
      )}
    </div>
  );
};
