import { Styles, withStyles, css } from '@recruitrobin/robin-theme/web-styles';

const styles = {
  root: css`
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  `,
  flexAlignment: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `,
} satisfies Styles<void>;

export const useStyles = withStyles(styles);
