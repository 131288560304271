import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { CandidateContext } from 'shared/contexts/CandidateContext/CandidateContext';
import { QueryKey } from 'types/query';
import { createContext, useContextSelector } from 'use-context-selector';
import { CandidateCardLabelOption, CandidateCardLabelsAddButtonRef, CandidateCardLabelsState } from './types';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { usePageContext } from 'hooks/shared/usePageContext';

const initialState: CandidateCardLabelsState = {
  labels: [],
  localLabels: [],
  setLocalLabels: () => {
    throw Error('not implemented');
  },
  setAddButtonRef: () => {
    throw Error('not implemented');
  },
  handleSave: () => {
    throw Error('not implemented');
  },
  handleDeleteLabel: () => {
    throw Error('not implemented');
  },
};

export const CandidateCardLabelsContext = createContext(initialState);

const emptyArr: string[] = [];
export const CandidateCardLabelsContextProvider = ({
  labels = emptyArr,
  children,
}: {
  labels?: string[];
  children: React.ReactNode;
}) => {
  const [addButtonRef, setAddButtonRef] = useState<CandidateCardLabelsAddButtonRef | null>(null);
  const onSaveLabels = useContextSelector(CandidateContext, (state) => state.onSaveLabels);
  const isLabelsLoading = useContextSelector(CandidateContext, (state) => state.isLoading.saveLabels);
  const pageContext = usePageContext();
  const queryClient = useQueryClient();
  const [contextMenuVisible] = useState(false);
  const [localLabels, setLocalLabels] = useState<string[]>([]);

  useEffect(() => {
    setLocalLabels(labels);
  }, [labels]);

  useEffect(() => {
    if (addButtonRef) {
      const { textFieldEl } = addButtonRef;

      if (contextMenuVisible && textFieldEl) {
        textFieldEl.querySelector('input')?.focus();
      }
    }
  }, [contextMenuVisible, addButtonRef]);

  const handleSave = async (overrideLabels?: string[]) => {
    const newLabels = overrideLabels ? overrideLabels : localLabels;
    if (JSON.stringify([...labels].sort()) === JSON.stringify([...newLabels].sort())) return;

    try {
      await onSaveLabels(newLabels);
      await queryClient.resetQueries([QueryKey.useTenantDistinctedLabelsQuery]);
    } catch (e) {
      setLocalLabels(labels);
    } finally {
      addButtonRef?.setLocalSearch('');
    }
  };

  const handleChangeLocalLabels = ({ key }: Pick<CandidateCardLabelOption, 'key'>) => {
    const alreadyExists = localLabels.includes(key);
    const newLabels = alreadyExists ? localLabels.filter((l) => l !== key) : [...localLabels, key];

    setLocalLabels(newLabels);

    return newLabels;
  };

  const handleDeleteLabel = (label: string) => {
    const isLoading = pageContext === PageContext.CentralInbox ? false : isLabelsLoading;

    if (isLoading) return;
    const newLabels = handleChangeLocalLabels({ key: label });

    handleSave(newLabels);
  };

  const value = useMemo<CandidateCardLabelsState>(() => {
    return { handleDeleteLabel, setAddButtonRef, handleSave, setLocalLabels, labels, localLabels };
  }, [localLabels, labels]);

  return <CandidateCardLabelsContext.Provider value={value}>{children}</CandidateCardLabelsContext.Provider>;
};
