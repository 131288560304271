import type { default as Candidate } from 'model/Candidate';
import { CandidateHistoryAction } from 'services/candidates/types';
import { useCandidateHistoryQuery } from 'shared/hooks/useCandidateHistoryQuery';
import { CandidateHistory } from 'shared/hooks/useCandidateHistoryQuery.types';
import { useIndeedConfig } from './useIndeedConfig';

const isValidIndeedMetadata = (metadata: CandidateHistory['metadata'], email: string | null): boolean =>
  !!metadata &&
  metadata.conversation_id != null &&
  metadata.job_seeker_key != null &&
  metadata.license_email_address === email;

export const useIndeedReply = (candidate: Candidate) => {
  const { email } = useIndeedConfig();
  const { data: history } = useCandidateHistoryQuery(candidate, { enabled: false });

  const reply = history?.find(
    (h) => h.action === CandidateHistoryAction.IndeedReceived && isValidIndeedMetadata(h.metadata, email),
  );

  return { isReply: !!reply, metadata: reply?.metadata ?? null };
};
