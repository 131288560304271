import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import {
  CandidateOutreachSendMethod,
  NotifyCandidateData,
  NotifyCandidateMetadata,
  NotifyCandidateMethod,
} from 'clients/CampaignsClient/CampaignsClient.types';
import { userClient } from 'clients/UserClient/UserClient';
import { encodeBase64 } from 'helpers/base64';
import { isAxiosError } from 'helpers/clientHelpers';
import { PageContext } from 'hooks/shared/usePageContext.types';
import { useContext } from 'react';
import { CANDIDATE_STATUS } from 'services/candidates';
import { LocalizationContext } from 'shared/contexts/LocalizationContext/LocalizationContext';
import { UserContext } from 'shared/contexts/UserContext/UserContext';
import { BadRequestException } from 'shared/exceptions/BadRequestException';
import { IndeedNoCreditsAvailableException } from 'shared/exceptions/IndeedNoCreditsAvailableException';
import { useBrowserExtension } from 'shared/hooks';
import { candidateHistoryQuerySetValue } from 'shared/hooks/useBrowserExtension/useCandidateHistoryQuery.setValue';
import { sharedUtils } from 'utils';
import { MailInputValue } from '../types';
import { useCandidateAction, WrappedHookParams } from './useCandidateAction';
import { WaitBeforeRemove } from './useWaitBeforeRemove';
import { useContextSelector } from 'use-context-selector';
import { SelectedCampaignContext } from 'shared/contexts/SelectedCampaignContext';
import { useIndeedConfig } from 'shared/hooks/useBrowserExtension/indeed/useIndeedConfig';
import { useIndeedSendMessageMutation } from 'shared/hooks/useBrowserExtension/indeed/useIndeedSendMessageMutation';
import { useIndeedReplyMessageMutation } from 'shared/hooks/useBrowserExtension/indeed/useIndeedReplyMessageMutation';
import type { IndeedJob } from 'shared/hooks/useBrowserExtension/indeed/indeed.types';
import { useSendLinkedinMessageMutation } from 'shared/hooks/useBrowserExtension/linkedin/useSendLinkedinMessageMutation';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKey } from 'types/query';

export const useContact = (params: WrappedHookParams) => {
  const campaignName = useContextSelector(SelectedCampaignContext, (s) => s.campaign?.name ?? '');
  const { dictionary, language } = useContext(LocalizationContext);
  const { creditBalance, email: indeedLicenseEmail } = useIndeedConfig();
  const { currentUser, mailSignOff } = useContext(UserContext);
  const {
    sendLinkedinConnectionRequest,
    sendLinkedinProMessage,
    existsLinkedinConversationWithCandidate,
    existsLinkedinInmailConversationWithCandidate,
  } = useBrowserExtension();
  const queryClient = useQueryClient();

  const indeedSendMessageMutation = useIndeedSendMessageMutation();
  const indeedReplyMessageMutation = useIndeedReplyMessageMutation();

  const sendLinkedinMessage = useSendLinkedinMessageMutation();

  return useCandidateAction<ContactHookCallbackParams>(
    async ({ candidate, data, sendMethod, extras }) => {
      let thread_id = undefined;
      let metadata: NotifyCandidateMetadata | undefined = undefined;

      if (sendMethod === 'linkedin') {
        const candidateConversation = await existsLinkedinConversationWithCandidate(
          candidate.es_person_id_without_platform,
        );

        if (candidateConversation !== undefined) {
          const response = await sendLinkedinMessage.mutateAsync({
            text: sharedUtils.htmlToPlainText(data.body ?? ''),
            candidateConversation,
          });

          if (response.status === 200) {
            thread_id = encodeBase64(`${currentUser?.id}-${candidate.es_person_id}`);
          }
        } else {
          const response = await sendLinkedinConnectionRequest({
            ...(data.body !== undefined && {
              customMessage: sharedUtils.htmlToPlainText(data.body),
            }),
            inviteeProfileUrn: `urn:li:fsd_profile:${candidate.es_person_id_without_platform}`,
          });

          if (response.status === 200) {
            thread_id = encodeBase64(`${currentUser?.id}-${candidate.es_person_id}`);
          }
        }
      } else if (sendMethod === 'indeed' && currentUser) {
        if (creditBalance === 0) {
          throw new IndeedNoCreditsAvailableException(dictionary.noCreditsAvailable);
        }

        const tenant = await userClient.getTenantCurrentUser();

        if (extras?.isIndeedReply && extras?.indeedMetadata) {
          await indeedReplyMessageMutation.mutateAsync({
            message: data.body as string,
            conversationId: extras.indeedMetadata?.conversation_id,
            jobSeekerKey: extras.indeedMetadata?.job_seeker_key,
          });
        } else {
          if (!extras?.indeedProjectId) throw TypeError('indedeProjectId must be provided');

          metadata = {
            job_info: {
              jobTitle: extras.indeedJob?.name,
            },
          };

          await indeedSendMessageMutation.mutateAsync({
            candidateId: candidate.es_person_id_without_platform,
            senderName: mailSignOff.fullName as string,
            senderCompany: `${tenant.data.name}`,
            jobTitle: data.subject as string,
            message: data.body as string,
            projectKey: extras.indeedProjectId,
            job: extras.indeedJob,
          });
        }

        const sanitizedName = candidate.name.split(' ').join('');
        const encodedName = encodeURIComponent(sanitizedName).replace(/%([0-9A-F]{2})/g, (_, p1) =>
          String.fromCharCode(parseInt(p1, 16)),
        );
        const base64Name = btoa(encodedName);

        thread_id = `${tenant.data.id}_${base64Name}`;
      } else if (sendMethod === 'linkedin_inmail') {
        const threadId = await existsLinkedinInmailConversationWithCandidate(candidate.es_person_id_without_platform);

        await sendLinkedinProMessage({
          candidate_id: candidate.es_person_id_without_platform,
          subject: data.subject as string,
          body: data.body as string,
          signature: mailSignOff.fullName as string,
          mailThread: threadId,
        });

        thread_id = encodeBase64(`${currentUser?.id}-${candidate.es_person_id}`);
      }

      function sanitizeText(text: string = '') {
        let value = text;
        const allowedCharacters = /^[a-zA-Z0-9\s&*():;'"!@\?\{\}]*$/;

        if (!allowedCharacters.test(value)) {
          const cleanedValue = value.replace(/[^a-zA-Z0-9\s&*():;'"!@\?\{\}]/g, '');
          value = cleanedValue;
        }

        return value;
      }

      const notifyCandidateData: NotifyCandidateData = {
        message: data.body,
        sign_off:
          sendMethod !== 'werknl'
            ? data.signOff || [mailSignOff?.fullName, mailSignOff?.phoneNumber].filter(Boolean).join('\n')
            : mailSignOff?.fullName,
        subject: sendMethod === 'werknl' ? sanitizeText(data.subject) : data.subject,
        email: data.email,
        phone_number: sendMethod === 'werknl' ? data.phoneNumber?.replace(/\D/g, '') : data.phoneNumber,
        website: data.website,
        status: 'success',
        provider_thread_id: thread_id,
        metadata: metadata,
      };

      const attachments =
        sendMethod === 'email' && extras?.attachments && extras.attachments.length > 0 ? extras.attachments : undefined;

      await campaignsClient.notifyCandidate({
        campaign_id: candidate.campaignId,
        candidate_id: candidate.es_person_id,
        send_method: sendMethod,
        data: notifyCandidateData,
        attachments,
      });
    },
    {
      onSuccess: ({
        fcParams: { candidate, sendMethod, data, extras, options: { waitBeforeRemoveFromList } = {} },
        gtm,
      }) => {
        gtm.contactCandidate(candidate.campaignId, candidate.es_person_id);
        gtm.contactSentMessage(candidate.campaignId, candidate.es_person_id, sendMethod);
        setTimeout(() => {
          queryClient.invalidateQueries([QueryKey.getInfiniteConversations]);
          queryClient.invalidateQueries([QueryKey.getConversations, { exact: false }]);
        }, 5000);

        return async ({ candidateOrchestrator: co, pageContext }) => {
          const outreachSendMethod = sendMethod as CandidateOutreachSendMethod;
          let metadata = {};

          switch (outreachSendMethod) {
            case CandidateOutreachSendMethod.Indeed:
              metadata = {
                license_email_address: indeedLicenseEmail,
                job_info: {
                  jobTitle: extras?.indeedJob?.name,
                },
              };
              break;

            default:
              break;
          }

          candidateHistoryQuerySetValue({
            language,
            candidate,
            currentUser,
            campaignName,
            content: { message: data.body, signoff: data.signOff, subject: data.subject },
            sendMethod: outreachSendMethod,
            metadata,
          });

          if (pageContext === PageContext.Campaign) {
            if (candidate.status === CANDIDATE_STATUS.NEW) {
              co.campaign.decreaseStatOf.currentJobBoard();
            }

            if (!candidate.reasons.includes('contacted')) {
              co.campaign.update.labels.add('contacted');
            }

            if ([CANDIDATE_STATUS.MATCHED, CANDIDATE_STATUS.CONTACTED].every((s) => s !== candidate.status)) {
              const handeRemoveFromList = () => {
                co.campaign.update.locally({ status: CANDIDATE_STATUS.CONTACTED });
                co.campaign.remove.fromCurrentList();
                co.campaign.reset.contactList();
                co.campaign.decreaseStatOf.currentList();
                co.campaign.increaseStatOf.contactList();
              };

              if (waitBeforeRemoveFromList) {
                waitBeforeRemoveFromList().then(({ removeFromList }) => {
                  if (removeFromList) {
                    handeRemoveFromList();
                  }
                });
              } else {
                handeRemoveFromList();
              }
            }
          } else if (pageContext === PageContext.RatedCandidates) {
            if (!candidate.reasons.includes('contacted')) {
              co.ratedCandidates.update.labels.add('contacted');
            }

            if ([CANDIDATE_STATUS.MATCHED, CANDIDATE_STATUS.CONTACTED].every((s) => s !== candidate.status)) {
              const handeRemoveFromList = () => {
                co.ratedCandidates.update.locally({ status: CANDIDATE_STATUS.CONTACTED });
                co.ratedCandidates.remove.fromCurrentList();
                co.ratedCandidates.reset.contactList();
                co.ratedCandidates.decreaseStatOf.currentList();
                co.ratedCandidates.increaseStatOf.contactList();
              };

              if (waitBeforeRemoveFromList) {
                waitBeforeRemoveFromList().then(({ removeFromList }) => {
                  if (removeFromList) {
                    handeRemoveFromList();
                  }
                });
              } else {
                handeRemoveFromList();
              }
            }
          } else if (pageContext === PageContext.Shared || pageContext === PageContext.Candidate) {
            if (!candidate.reasons.includes('contacted')) {
              co.share.update.labels.add('contacted');
            }

            if (candidate.status !== CANDIDATE_STATUS.MATCHED) {
              co.share.moveCandidateTo.contactList();
            }
          }
        };
      },
      onError: ({ fcParams: { candidate, sendMethod }, gtm }) => {
        gtm.contactCandidateFailed(candidate.campaignId, candidate.es_person_id, sendMethod);

        return ({ data }) => {
          if (isAxiosError(data) && data.response?.status === 400 && sendMethod === 'email') {
            throw new BadRequestException();
          }

          throw data;
        };
      },
    },
  )(params);
};

export type ContactHookCallbackParams = {
  data: MailInputValue;
  sendMethod: NotifyCandidateMethod;
  extras?: {
    indeedProjectId?: string;
    indeedJob?: IndeedJob | null;
    indeedMetadata?: Record<string, any> | null;
    isIndeedReply?: boolean;
    attachments?: File[];
  };
  options?: {
    waitBeforeRemoveFromList?: WaitBeforeRemove;
  };
};
