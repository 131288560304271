import { useInfiniteQuery } from '@tanstack/react-query';
import { campaignsClient } from 'clients/CampaignsClient/CampaignsClient';
import { conversationsClient } from 'clients/ConversationsClient/ConversationsClient';
import { QueryKey } from 'types/query';

const CONVERSATION_LIMIT = 20;

export const useInfiniteConversationsQuery = () => {
  return useInfiniteQuery(
    [QueryKey.getInfiniteConversations],
    async ({ pageParam }) => {
      const offset = pageParam?.offset ?? 0;

      const conversationsResponse = await conversationsClient.getConversations({
        limit: CONVERSATION_LIMIT,
        offset: offset,
      });
      const esPersonIds = conversationsResponse.data.results.map((conversation) => conversation.es_person_id);

      const candidatesResponse = await campaignsClient.searchEsCampaignCandidate({ esPersonIds });

      return {
        ...conversationsResponse.data,
        results: conversationsResponse.data.results.map((result) => ({
          ...result,
          user: {
            firstName: result.user.first_name,
            lastName: result.user.last_name,
          },
          unreadCount: result.unread_count,
          lastMessageDate: result.last_message_date,
          lastMessageFromRecruiter: result.last_message_from_recruiter,
          candidateName: result.candidate_name,
          esPersonId: result.es_person_id,
          conversationSource: result.conversation_source,
          candidate: candidatesResponse.data.results.find((candidate) => candidate.es_person_id == result.es_person_id),
        })),
      };
    },
    {
      getNextPageParam: (_, allPages) => {
        const lastPage = allPages.at(allPages.length - 1);
        if (lastPage?.next === null) return undefined;

        const total = allPages.length;
        return { offset: total * CONVERSATION_LIMIT };
      },
    },
  );
};
